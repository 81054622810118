import { Link } from "react-router-dom";
import Logo from "../../assets/svgs/Logo.svg";
import Button from "../Buttons/PrimaryButton";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdCancel } from "react-icons/md";
import { useState } from "react";

const Navbar = () => {
    const [productsDropDownOpened, setProductsDropDownOpened] =
        useState<boolean>(false);

    const handleToggleProductsDropdown = () => {
        setProductsDropDownOpened((prev) => !prev);
    };

    const [hamburgerDropDownOpened, setHamburgerDropDownOpened] =
        useState<boolean>(false);

    return (
        <div className="px-[3%] sm:px-[7%] lg:px-[15%] py-4 flex justify-between items-center relative">
            <Link to={"/"}>
                <img src={Logo} alt="logo" className="h-12" />
            </Link>
            <div className="lg:flex hidden items-center gap-4 text-dim absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                <div className="relative">
                    <button
                        onClick={handleToggleProductsDropdown}
                        className="cursor-pointer flex items-center gap-1"
                    >
                        Products
                        {productsDropDownOpened ? (
                            <IoMdArrowDropup />
                        ) : (
                            <IoMdArrowDropdown />
                        )}
                    </button>
                    {productsDropDownOpened && (
                        <div
                            className={`absolute w-[200px] mt-2 bg-[#323234] text-white rounded flex flex-col gap-0`}
                        >
                            <Link
                                to="/products/buddy"
                                className={`hover:bg-[#727272] p-2  ${
                                    window.location.pathname ===
                                    "/products/buddy"
                                        ? "border-l-2 border-primary"
                                        : ""
                                }`}
                                onClick={handleToggleProductsDropdown}
                            >
                                Vils Buddy
                            </Link>
                            <Link
                                to="/products/placements-pro"
                                className={`hover:bg-[#727272] p-2  ${
                                    window.location.pathname ===
                                    "/products/placements-pro"
                                        ? "border-l-2 border-primary"
                                        : ""
                                }`}
                                onClick={handleToggleProductsDropdown}
                            >
                                Vils Placement
                            </Link>
                            <Link
                                to="/products/hr-pro"
                                className={`hover:bg-[#727272] p-2  ${
                                    window.location.pathname ===
                                    "/products/hr-pro"
                                        ? "border-l-2 border-primary"
                                        : ""
                                }`}
                                onClick={handleToggleProductsDropdown}
                            >
                                Vils HR
                            </Link>
                        </div>
                    )}
                </div>
                <Link to="/about-us">
                    <p className="cursor-pointer">Company</p>
                </Link>
            </div>
            <div className="lg:flex items-center gap-4 hidden">
                <Link to="/products">
                    <p className="text-primary cursor-pointer">Login</p>
                </Link>
            </div>
            <div className="lg:hidden relative ">
                {hamburgerDropDownOpened ? (
                    <MdCancel
                        size={30}
                        onClick={() =>
                            setHamburgerDropDownOpened(!hamburgerDropDownOpened)
                        }
                    />
                ) : (
                    <RxHamburgerMenu
                        size={30}
                        onClick={() =>
                            setHamburgerDropDownOpened(!hamburgerDropDownOpened)
                        }
                        style={{
                            color: "cyan",
                        }}
                    />
                )}

                {hamburgerDropDownOpened && (
                    <div className="fixed top-20 left-0 p-5 rounded-md  w-[92%] m-5 mr-6 bg-[#323234] text-white text-center flex flex-col  gap-4 z-50 ">
                        <Link to="/about-us">
                            <p
                                onClick={() =>
                                    setHamburgerDropDownOpened(
                                        !hamburgerDropDownOpened,
                                    )
                                }
                                className="cursor-pointer  py-2 px-4 font-bold text-lg  border-black rounded-3xl"
                            >
                                Company
                            </p>
                        </Link>
                        <button
                            onClick={handleToggleProductsDropdown}
                            className="cursor-pointer w-full text-center font-bold text-lg  border-black rounded-3xl py-2 px-4"
                        >
                            Products
                        </button>
                        {productsDropDownOpened && (
                            <div className="w-full bg-[#323234] text-white  rounded flex flex-col gap-0">
                                <Link
                                    to="/products/buddy"
                                    className={`hover:bg-[#727272] p-2  ${
                                        window.location.pathname ===
                                        "/products/buddy"
                                            ? "border-l-2 border-primary"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        setHamburgerDropDownOpened(
                                            !hamburgerDropDownOpened,
                                        )
                                    }
                                >
                                    Vils Buddy
                                </Link>
                                <Link
                                    to="/products/placements-pro"
                                    className={`hover:bg-[#727272] p-2  ${
                                        window.location.pathname ===
                                        "/products/placements-pro"
                                            ? "border-l-2 border-primary"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        setHamburgerDropDownOpened(
                                            !hamburgerDropDownOpened,
                                        )
                                    }
                                >
                                    Vils Placement
                                </Link>
                                <Link
                                    to="/products/hr-pro"
                                    className={`hover:bg-[#727272] p-2  ${
                                        window.location.pathname ===
                                        "/products/hr-pro"
                                            ? "border-l-2 border-primary"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        setHamburgerDropDownOpened(
                                            !hamburgerDropDownOpened,
                                        )
                                    }
                                >
                                    Vils HR
                                </Link>
                            </div>
                        )}

                        <Link to="https://buddy.vils.ai/auth">
                            <p className="text-primary cursor-pointer text-lg  border-t-2 pt-4">
                                Login
                            </p>
                        </Link>
                        <Link to="https://buddy.vils.ai/auth">
                            <Button label="Sign Up" className="px-6 text-lg " />
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Navbar;
