import Logo from "../../assets/svgs/Logo.svg";

import { Link } from "react-router-dom";

import ItemsCard from "./ItemsCard";

const MobileFooter = () => {
    const ResourcesItems = [
        {
            name: "Blogs",
            url: "/",
        },
        {
            name: "Articles",
            url: "/",
        },
        {
            name: "Research Papers",
            url: "/",
        },
        {
            name: "White Papers",
            url: "/",
        },
    ];
    const LegalItems = [
        {
            name: "Terms of Service",
            url: "/terms-and-conditions",
        },
        {
            name: "Privacy Policy",
            url: "/",
        },
    ];
    const QuickLinksItems = [
        {
            name: "About Us",
            url: "/about-us",
        },
        {
            name: "Pricing",
            url: "/",
        },
        {
            name: "FAQs",
            url: "/",
        },
        {
            name: "Login",
            url: "https://buddy.vils.ai/auth",
        },
        {
            name: "Register",
            url: "https://buddy.vils.ai/auth",
        },
        {
            name: "Contact Us",
            url: "/contact-us",
        },
    ];
    return (
        <div className="ps-[5%] pr-[5%] flex flex-col md:flex-row gap-10 mt-20 cursor-pointer">
            <div className="  flex flex-col gap-4">
                <Link to="/">
                    <img src={Logo} alt="logo" className="w-20 mb-6" />
                </Link>
                <ItemsCard items={QuickLinksItems} label="Quick Links" />
                <ItemsCard items={ResourcesItems} label="Resources" />
                <ItemsCard items={LegalItems} label="Legal" />
                <p className="w-[70%] my-6">
                    Plot No.5, Survey No 62, 3rd Floor, Lake Front Towers,
                    Inorbit Mall Rd, Hitech city, Madhapur, Telangana, India,
                    500081
                </p>
                <div className="flex gap-4 h-15 mb-4 ">
                    <a
                        href="https://www.youtube.com/@VILS-AI"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={
                                "https://studentpro-image.s3.ap-south-1.amazonaws.com/9908c4e2-750a-459e-a37e-68fffb5ca7ee.png"
                            }
                            loading="lazy"
                            alt="yt"
                        />
                    </a>
                    <a
                        href="https://www.instagram.com/vils.ait/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={
                                "https://studentpro-image.s3.ap-south-1.amazonaws.com/71597876-a2ce-49d7-bc9c-4c24e5aef322.png"
                            }
                            loading="lazy"
                            alt="insta"
                        />
                    </a>
                    <a
                        href="https://twitter.com/VILSAITPVT"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={
                                "https://studentpro-image.s3.ap-south-1.amazonaws.com/25a702bf-a7eb-4abc-b3cc-f9b6deca90d5.png"
                            }
                            loading="lazy"
                            alt="twitter"
                        />
                    </a>
                    <a
                        href="https://www.linkedin.com/company/vils-ait/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={
                                "https://studentpro-image.s3.ap-south-1.amazonaws.com/40fb71bd-fc77-4c68-aa3b-3718ed683748.png"
                            }
                            loading="lazy"
                            alt="linkedin"
                        />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default MobileFooter;
