import React from "react";
import Logo from "../../assets/svgs/Logo_Black.svg";
import Acadsense from "../../assets/svgs/Acadsense.svg";
import StudentPro from "../../assets/svgs/StudentPro.svg";
import PlacementPortal from "../../assets/svgs/PlacementPortal.svg";
import SkillAssess from "../../assets/svgs/SkillAssess.svg";

const ProductPage = () => {
    return (
        <main className="h-[100%]">
            <header className="mt-10 ml-10">
                <img src={Logo} alt="Logo" />
            </header>
            <section className="flex flex-col items-center mt-6">
                <h3 className="font-bold text-3xl">Select product to login</h3>
                <p className="font-medium text-3xl mt-2">
                    Choose the right pathway for your career
                </p>
                <p className="font-medium text-md mt-2">
                    Discover your pathway with our various products
                </p>
            </section>
            <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-10 px-6 pl-14 ">
                <Card
                    imgsrc={Acadsense}
                    heading="Acadsense"
                    description="Master your academics with topic-wise practice and Course Outcome-specific tests. Improve your understanding and achieve better results."
                    isDisabled={false}
                    redirect="https://acadsense.vils.ai/auth"
                />
                <Card
                    imgsrc={SkillAssess}
                    heading="SkillAsses"
                    description="Focused assessments to evaluate your skills and understand your abilities effectively."
                    isDisabled={true}
                />
                <Card
                    imgsrc={StudentPro}
                    heading="StudentPro"
                    description="Prepare for jobs with aptitude tests, communication practice, and mock interviews. Track your progress and build job-ready skills"
                    isDisabled={true}
                />
                <Card
                    imgsrc={PlacementPortal}
                    heading="Placement Portal"
                    description="Unlock student performance insights to enable targeted training, drive academic growth, and boost placement readiness."
                    isDisabled={true}
                />
            </section>
        </main>
    );
};

interface CardProps {
    imgsrc: string;
    heading: string;
    description: string;
    isDisabled?: boolean;
    redirect?: string;
}

const Card: React.FC<CardProps> = ({
    imgsrc,
    heading,
    description,
    isDisabled,
    redirect,
}) => {
    const handleClick = () => {
        if (!isDisabled && redirect) {
            window.location.href = redirect;
        }
    };
    return (
        <div
            className={`flex flex-col relative p-4 rounded-xl shadow-[0px_0px_4px_0px_rgba(0,0,0,0.25)] border bg-white w-[90%]`}
        >
            <div className="">
                <img
                    src={imgsrc}
                    alt={heading}
                    className="w-20 h-20 object-cover"
                />
            </div>

            <h4 className="mt-4 text-xl font-semibold">{heading}</h4>
            <p className="mt-2 mb-16 text-sm text-gray-600 text-justify">
                {description}
            </p>

            {/* <div className="absolute bottom-0 right-0">
                <button
                    className={`relative group flex items-center justify-center h-10 rounded-[3px] rounded-br-xl transition-all duration-300 ${
                        isDisabled
                            ? "bg-gray-400 text-white cursor-not-allowed"
                            : "bg-blue-600 text-white hover:bg-blue-700"
                    }`}
                    style={{
                        width: "40px",
                    }}
                    onMouseEnter={(e) => {
                        if (!isDisabled) e.currentTarget.style.width = "100px";
                    }}
                    onMouseLeave={(e) => {
                        if (!isDisabled) e.currentTarget.style.width = "40px";
                    }}
                    disabled={isDisabled}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-6 h-6 transition-transform duration-200 group-hover:translate-x-0"
                    >
                        <path d="M5 12h14M12 5l7 7-7 7" />
                    </svg>

                    <span
                        className={`ml-2 text-sm font-medium whitespace-nowrap transition-all duration-300 opacity-0 group-hover:opacity-100 group-hover:translate-x-1 ${
                            isDisabled ? "hidden" : "text-white"
                        }`}
                    >
                        Login
                    </span>
                </button>
            </div> */}
            <div className="absolute bottom-0 right-0">
                <button
                    className={`relative group flex items-center justify-center h-10 rounded-[3px] rounded-br-xl transition-all duration-300 ${
                        isDisabled
                            ? "bg-gray-400 text-white cursor-not-allowed"
                            : "bg-blue-600 text-white hover:bg-blue-700"
                    }`}
                    style={{
                        width: "40px",
                    }}
                    onMouseEnter={(e) => {
                        if (!isDisabled) e.currentTarget.style.width = "100px";
                    }}
                    onMouseLeave={(e) => {
                        if (!isDisabled) e.currentTarget.style.width = "40px";
                    }}
                    onClick={handleClick}
                    disabled={isDisabled}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className={`absolute left-2 w-6 h-6 transition-transform duration-200 ${
                            isDisabled ? "text-gray-300" : "text-white"
                        }`}
                    >
                        <path d="M5 12h14M12 5l7 7-7 7" />
                    </svg>

                    {!isDisabled && (
                        <span
                            className={`ml-6 text-sm font-medium whitespace-nowrap transition-all duration-300 opacity-0 group-hover:opacity-100`}
                        >
                            Login
                        </span>
                    )}
                </button>
            </div>
        </div>
    );
};

export default ProductPage;
