import { Suspense, lazy } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Loader from "./components/Loaders";
import Navbar from "./components/Navbar";
import MobileFooter from "./components/MobileFooter";
import ProductPage from "./pages/ProductPage";

const HomePage = lazy(() => import("./pages/HomePage"));
const AboutUsPage = lazy(() => import("./pages/AboutUsPage"));
const PlacementsProPage = lazy(() => import("./pages/PlacementsProPage"));
const HRProPage = lazy(() => import("./pages/HRProPage"));
const StudentsProPage = lazy(() => import("./pages/StudentsProPage"));
const TermsAndConditionPage = lazy(
    () => import("./pages/TermsAndConditionsPage"),
);
const ContactUsPage = lazy(() => import("./pages/ContactUsPage"));

const App = () => {
    const location = useLocation();
    const hideNavbarFooterRoutes = ["/products"];
    const isNavbarFooterHidden = hideNavbarFooterRoutes.some((route) =>
        location.pathname.startsWith(route),
    );
    return (
        <div
            className={`${
                isNavbarFooterHidden
                    ? " text-black"
                    : "bg-black text-white w-full min-h-screen sm:pb-8"
            }  `}
        >
            <Suspense fallback={<LoadingScreen />}>
                {!isNavbarFooterHidden && <Navbar />}
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route
                        path="/products/placements-pro"
                        element={<PlacementsProPage />}
                    />
                    <Route path="/products/hr-pro" element={<HRProPage />} />
                    <Route
                        path="/products/buddy"
                        element={<StudentsProPage />}
                    />
                    <Route path="/about-us" element={<AboutUsPage />} />
                    <Route path="/products" element={<ProductPage />} />
                    <Route
                        path="/terms-and-conditions"
                        element={<TermsAndConditionPage />}
                    />
                    <Route path="/contact-us" element={<ContactUsPage />} />
                </Routes>
                {!isNavbarFooterHidden && (
                    <div className="lg:hidden">
                        <MobileFooter />
                    </div>
                )}
            </Suspense>
        </div>
    );
};

const LoadingScreen = () => {
    return (
        <div className="h-screen w-screen grid place-content-center">
            <Loader
                smallArcColor="text-gray-400"
                largeArcColor="text-primary"
                loadingText={true}
            />
        </div>
    );
};

export default App;
